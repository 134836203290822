export default class SlickVehicleGallery {
    public static init() {
		const autoPlay = ($('.phyron-thumbnail').length > 0 ? false: true);
		if ($('.slick--vehicle-gallery-main').length > 0) {
			$('.slick--vehicle-gallery-main').slick({
				slidesToShow: 1,
				slidesToScroll: 1,
				arrows: false,
				asNavFor: '.slick--vehicle-gallery-thumbs',
				autoplaySpeed: 3000,
				vertical: true,
				verticalSwiping: true,
				autoplay: autoPlay,
				responsive: [
					{
						breakpoint: 767,
						settings: {
							vertical: false,
							verticalSwiping: false
						}
					}
				],
			});
			$('.slick--vehicle-gallery-thumbs').slick({
				slidesToShow: 5,
				slidesToScroll: 1,
				arrows: true,
				prevArrow: "<span class='slick-prev thumbs'>&lt;</span>",
				nextArrow: "<span class='slick-next thumbs'>&gt;</span>",
				asNavFor: '.slick--vehicle-gallery-main',
				focusOnSelect: true,
				autoplaySpeed: 3000,
				vertical: true,
				verticalSwiping: true,
				adaptiveHeight: true,
				centerMode: true,
				centerPadding: '0px',
				autoplay: autoPlay,
				responsive: [
					{
						breakpoint: 767,
						settings: {
							slidesToShow: 3,
							slidesToScroll: 1,
							vertical: false,
							verticalSwiping: false
						}
					}
				],
			});
			$('.slick--vehicle-gallery-main').each(function () {
				(<any>$(this)).magnificPopup({
					delegate: 'a',
					type: 'image',
					gallery: {
						enabled: true
					}
				});
			});
			// $( '.slick--vehicle-gallery-main' ).on( 'lazyLoaded', function( evt, slick, $img ) {

			// 	$img
			// 		// Find the parent <picture> tag of img
			// 		.closest('picture')
			// 		// Find <source> tags with data-lazy-srcset attribute
			// 		.find('source[data-lazy-srcset]')
			// 		// Copy data-lazy-srcset to srcset
			// 		.each(function (i: any, $source: any) {
			// 			$source = $($source);
			// 			$source.attr('srcset', $source.data('lazy-srcset'));
			// 		}); 
			
			// } );
			// $( '.slick--vehicle-gallery-thumbs' ).on( 'lazyLoaded', function( evt, slick, $img ) {

			// 	$img
			// 		// Find the parent <picture> tag of img
			// 		.closest('picture')
			// 		// Find <source> tags with data-lazy-srcset attribute
			// 		.find('source[data-lazy-srcset]')
			// 		// Copy data-lazy-srcset to srcset
			// 		.each(function (i: any, $source: any) {
			// 			$source = $($source);
			// 			$source.attr('srcset', $source.data('lazy-srcset'));
			// 		}); 
			// } );
		}
	}
}